import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import Header from "../Global/Header";
import Footer from "../Global/Footer";
import SearchForm from "./SearchForm";
import HotelsList from "./HotelsList";
import { search } from "../../redux/actions/search";

const scrollToRef = (ref) => {
    ref.current && window.scrollTo(0, ref.current.offsetTop);
};

function Search(props) {
    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef);

    const dispatch = useDispatch();
    const [criteria, setCriteria] = useState({
        check_in: "",
        check_out: "",
        city: "",
        guest: "",
        star: [],
        min_price: 0,
        max_price: 1000,
        features: []
    });

    useEffect(() => {
        setCriteria({
            ...criteria,
            check_in: props.match.params.check_in || "",
            check_out: props.match.params.check_out || "",
            city: props.match.params.city || "",
            guest: props.match.params.guest || ""
        });
        search(dispatch, criteria);
    }, [props.match.params]); // eslint-disable-line
    return (
        <>
            <Header />
                <main>
                    <section className="breadcrumb-area d-flex align-items-center" style={{backgroundImage:`url(https://images.unsplash.com/photo-1578683010236-d716f9a3f461?auto=format&fit=crop&q=80&w=3870&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-center">
                                        <div className="breadcrumb-title">
                                            <h2>Search</h2>
                                            <div className="breadcrumb-wrap">
                                    
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Search</li>
                                            </ol>
                                        </nav>
                                    </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                    <div className="about-area5 about-p p-relative">
                        <div className="container pt-120 pb-40">
                            <div className="row">
                                <SearchForm
                                    {...props}
                                    criteria={criteria}
                                    setCriteria={setCriteria}
                                    executeScroll={executeScroll}
                                />
                                <HotelsList
                                    _ref={myRef}
                                    criteria={criteria}
                                    setCriteria={setCriteria}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            <Footer />
        </>
    );
}

export default Search;
