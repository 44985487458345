import React from "react";
import Footer from "../../Global/Footer";
import Header from "../../Global/Header";
import UserSiderBar from "../UserSiderBar";
import UserDropDown from "../UserDropDown";
import UserProfileMain from "./UserProfileMain";
import ErrorMessage from "../../Global/ErrorMessage";
import SuccessMessage from "../../Global/SuccessMessage";
import { useSelector } from "react-redux";
import Loading from "../../Global/Loading";

function UserProfile(props) {
    const state = useSelector((state) => state);

    return (
        <>
            <Header />
            {state.users.loading && <Loading />}
            {state.users.success === false && (
                <ErrorMessage errors={state.users.errors} />
            )}
            {state.users.success && <SuccessMessage message="Success" />}
            {/* <main className="bg-gray-100 min-h-screen flex flex-col md:flex-row md:justify-center p-5 text-gray-800">
                <UserSiderBar />
                <UserDropDown />
                <UserProfileMain />
            </main> */}

            <main>
                <section className="breadcrumb-area d-flex align-items-center" style={{backgroundImage: `url(https://images.unsplash.com/photo-1551882547-ff40c63fe5fa?auto=format&fit=crop&q=80&w=3870&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-12">
                                <div className="breadcrumb-wrap text-center">
                                    <div className="breadcrumb-title">
                                        <h2>Profile</h2>    
                                        <div className="breadcrumb-wrap">
                                
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                        </ol>
                                    </nav>
                                </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>

                <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-4 order-1">
                                <UserSiderBar /> 
                            </div>

                            <div className="col-lg-8 order-2">
                                <UserProfileMain />
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
}

export default UserProfile;
