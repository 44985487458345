import React from "react";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import SignupForm from "./SignupForm";

function Signup() {
    return (
        <>
            <Header />
                <main>
                    <SignupForm />
                </main>
            <Footer />
        </>
    );
}

export default Signup;
