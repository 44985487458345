import React, { useState, useEffect } from "react";
import useSecureLs from "../Global/useSecureLs";

function BookingDetails({ booking, setBooking }) {
    const [price, setPrice] = useState(0);
    const [room_id] = useSecureLs("room_id");
    const [room_name] = useSecureLs("room_name");
    const [room_price] = useSecureLs("room_price");
    const [room_image] = useSecureLs("room_image");
    const room = {
        room_id,
        name: room_name,
        price: room_price,
        image: room_image
    };

    const calcPrice = () => {
        if (booking.check_in !== "" && booking.check_out !== "") {
            const oneDay = 24 * 60 * 60 * 1000;
            const check_out = new Date(booking.check_out);
            const check_in = new Date(booking.check_in);
            const diffDays = Math.floor((check_out - check_in) / oneDay);

            if (diffDays > 0) {
                setPrice(room.price * diffDays);
            }
        }
    };

    useEffect(() => {
        calcPrice();
        setBooking({
            ...booking,
            amount: price
        });
    }, [booking.check_in, booking.check_out, price]); // eslint-disable-line
    return (
        <>

            <div className="section-title center-align mb-40 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                <h4>
                    Step 1: Check Details
                </h4>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="contact-field p-relative c-name mb-20">                                    
                        <input 
                            id="check-in"
                            type="date"
                            style={{padding: 15}}
                            value={booking.check_in}
                            onChange={(e) => {
                                calcPrice();
                                setBooking({
                                    ...booking,
                                    check_in: e.target.value,
                                    amount: price
                                });
                            }}
                        />
                    </div>                               
                </div>

                <div className="col-lg-6 col-md-6">                               
                    <div className="contact-field p-relative c-subject mb-20">                                   
                        <input
                            id="check-out"
                            type="date"
                            style={{padding: 15}}
                            value={booking.check_out}
                            onChange={(e) => {
                                calcPrice();
                                setBooking({
                                    ...booking,
                                    check_out: e.target.value,
                                    amount: price
                                });
                            }}
                        />
                    </div>
                </div>                       
            </div>
        </>
    );
}

export default BookingDetails;
