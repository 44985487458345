import React from "react";
import TitleSection from "../Global/TitleSection";
import RoomCard from "../Global/RoomCard";

function SuggestedRooms({ suggestedRooms }) {
    return (
        <section id="services" className="services-area pt-20 pb-90">
            <TitleSection title="Other Rooms You Might Like" />
            <div className="container">
                <div className="row">
                    {suggestedRooms.map((room) => {
                        return <RoomCard key={room.id} room={room} />;
                    })}
                </div>
            </div>
        </section>
    );
}

export default SuggestedRooms;
