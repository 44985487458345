import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';

function HotelItem({ hotel }) {
    const [rating, setRating] = useState([]);
    useEffect(() => {
        switch (Math.round(parseInt(hotel.rating))) {
            case 10:
                setRating(["10/10", "Amazing"]);
                break;
            case 9:
                setRating(["9/10", "Great"]);
                break;
            case 8:
                setRating(["8/10", "Very Good"]);
                break;
            case 7:
                setRating(["7/10", "Good"]);
                break;
            case 6:
                setRating(["6/10", "Fine"]);
                break;
            case 5:
                setRating(["5/10", "Average"]);
                break;
            case 4:
                setRating(["4/10", "Bad"]);
                break;
            case 3:
                setRating(["3/10", "Very Bad"]);
                break;
            case 2:
                setRating(["2/10", "Horrible"]);
                break;
            case 1:
                setRating(["1/10", "Appaling"]);
                break;

            default:
                setRating(["?", "Not Rated Yet"]);
                break;
        }
    }, [hotel]); // eslint-disable-line

    return (
        
        <div className="col-xl-6 col-md-6">
            <div className="single-services ser-m mb-30">
                <div className="services-thumb">
                    <a className="gallery-link popup-image" href={
                            hotel.image
                                ? `${process.env.REACT_APP_BASE_URL}/${hotel.image}`
                                : "http://placehold.it/300x300?text=hotel"
                        }>
                    <img src={
                            hotel.image
                                ? `${process.env.REACT_APP_BASE_URL}/${hotel.image}`
                                : "http://placehold.it/300x300?text=hotel"
                        } alt="img"/>
                    </a>
                </div>
                <div className="services-content"> 
                    <div className="day-book">
                        <ul>
                            <li>
                                <CurrencyFormat style={{marginBottom: 0}} value={hotel.min_price} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} />
                            </li>
                            <li><a href="{`/hotel/${hotel.id}`}">{rating[0]} {rating[1]}</a></li>
                        </ul>
                    </div>
                    <h4><Link to={`/hotel/${hotel.id}`}>{hotel.name}</Link></h4> 
                    <p>{hotel.city}</p>
                    <p>{hotel.address}</p>
                    <div className="icon">
                        <ul>
                            <li>
                                {Array(hotel.star)
                                    .fill()
                                    .map((x, i) => (
                                        <i
                                            className="fas fa-star fa-xs text-orange-400" style={{color: 'yellow'}}
                                            key={i}
                                        ></i>
                                ))}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HotelItem;
