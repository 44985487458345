import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import { getHotelImages } from "../../redux/actions/hotels";

function Contact() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    useEffect(() => {
        getHotelImages(dispatch);
    }, []); // eslint-disable-line

    return (
        <>
            <Header />
                <main>
                    <section className="breadcrumb-area d-flex align-items-center" style={{backgroundImage:`url(https://images.unsplash.com/photo-1496417263034-38ec4f0b665a?auto=format&fit=crop&q=80&w=3871&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-center">
                                        <div className="breadcrumb-title">
                                            <h2>Contact Us</h2>    
                                            <div className="breadcrumb-wrap">
                                    
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Contact</li>
                                            </ol>
                                        </nav>
                                    </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>

                    <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix">
                        <div className="container">
                        
                            <div className="row justify-content-center align-items-center">
                                
                                    <div className="col-lg-12 order-1">
                                        
                                    <div className="contact-info">
                                            <div className="single-cta pb-30 mb-30 wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                                                <div className="f-cta-icon">
                                                    <i className="far fa-map"></i>
                                                </div>
                                                <h5>Office Address</h5>
                                                <p>Jl. Yudistira No.Desa, Banjar Asem, Kec. Seririt, Kabupaten Buleleng, Bali 81153 </p>
                                            </div>
                                                <div className="single-cta pb-30 mb-30 wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                                                <div className="f-cta-icon">
                                                    <i className="far fa-phone"></i>
                                                </div>
                                                <h5>Phone</h5>
                                                <p>0362 3361428</p>
                                            </div>
                                                <div className="single-cta wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                                                <div className="f-cta-icon">
                                                    <i className="far fa-envelope-open"></i>
                                                </div>
                                                <h5>Message Us</h5>
                                                <p> <a href="mailto:smknegeriseririt2@gmail.com">smknegeriseririt2@gmail.com</a></p>
                                            </div>
                                        </div>							
                                </div>
                            </div>
                            
                        </div>
                    </section>
                </main>
            <Footer/>
        </>
    );
}

export default Contact;