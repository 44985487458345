import React, { useEffect } from "react";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import ClientReviews from "./ClientReviews";
import Hero from "./Hero";
import Welcome from "./Welcome";
import FeaturedHotels from "./FeaturedHotels";

function Home() {
    useEffect(() => {
        document.title = `SMK N 2 Seririt | Beranda`;
    }, []); // eslint-disable-line
    return (
        <>
            <Header />

            <main>
                <Hero />

                <Welcome />

                {/* <Border /> */}

                <FeaturedHotels />

                {/* <Border /> */}

                <ClientReviews />

                {/* <Border /> */}
            </main>

            <Footer />
        </>
    );
}

export default Home;
