import React, { useState, useEffect } from "react";
import { addBooking } from "../../redux/actions/bookings";
import { setSuccess } from "../../redux/actions/global";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import BookingDetails from "./BookingDetails";
import PaymentForm from "./PaymentForm";
import Border from "../Global/Border";
import Loading from "../Global/Loading";
import ErrorMessage from "../Global/ErrorMessage";
import SuccessMessage from "../Global/SuccessMessage";
import useSecureLs from "../Global/useSecureLs";

function BookForm() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [user_id] = useSecureLs("user_id");
    const [room_id] = useSecureLs("room_id");

    const [booking, setBooking] = useState({
        full_name: ["", ""],
        number: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
        check_in: "",
        check_out: "",
        user_id,
        room_id,
        amount: 0
    });

    const maxLengthCheck = (e) => {
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength);
        }
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        addBooking(dispatch, booking, state.auth.token);
    };

    let history = useHistory();

    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccess(dispatch, null);
            if (state.bookings.success) {
                history.push(`/user-bookings/${booking.user_id}`);
            }
        }, 2000);
        return () => clearTimeout(timer);
    }, [state.bookings.success]); // eslint-disable-line

    useEffect(() => {
        document.title = `Book Your Hotel`;
    }, []); // eslint-disable-line
    return (
        <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-12 order-2">
                        <div className="contact-bg02">
                            {state.bookings.loading && <Loading />}
                            {state.bookings.success === false && (
                                <ErrorMessage errors={state.bookings.errors} />
                            )}
                            {state.bookings.success && <SuccessMessage message="Success" />}
                                <BookingDetails booking={booking} setBooking={setBooking} />
                                <Border borderColor="border-gray-500" my="8" />
                                <PaymentForm
                                    booking={booking}
                                    setBooking={setBooking}
                                    maxLengthCheck={maxLengthCheck}
                                    onSubmitHandler={onSubmitHandler}
                                />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BookForm;
