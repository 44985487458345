import React from "react";

function SearchFormMain({ criteria, setCriteria, cities }) {
    return (
        <>
            <div className="col-lg-12">
                <div className="contact-field p-relative c-name mb-20">
                    <label><i className="fal fa-badge-check"></i> Check In
                        Date</label>
                    <input
                        id="check-in"
                        type="date"
                        value={criteria.check_in}
                        onChange={(e) =>
                            setCriteria({ ...criteria, check_in: e.target.value })
                        }
                    />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="contact-field p-relative c-subject mb-20">
                    <label><i className="fal fa-times-octagon"></i> Check Out
                        Date</label>
                    <input 
                        id="check-out"
                        type="date"
                        value={criteria.check_out}
                        onChange={(e) =>
                            setCriteria({ ...criteria, check_out: e.target.value })
                        } 
                    />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="contact-field p-relative c-subject mb-20">
                    <label><i className="fal fa-concierge-bell"></i> City</label>
                    <select id="city"                
                        placeholder="Select one"
                        value={criteria.city}
                        onChange={(e) =>
                            e.target.value === "all"
                                ? setCriteria({ ...criteria, city: "" })
                                : setCriteria({ ...criteria, city: e.target.value })
                        }
                    >
                        <option value="all">All Cities</option>
                        {cities &&
                        cities.map((city, i) => {
                            return (
                                <option value={city} key={i}>
                                    {city}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>

            <div className="col-lg-12">
                <div className="contact-field p-relative c-option mb-20">
                    <label><i className="fal fa-users"></i> Guests</label>
                    <select 
                        id="guest-count"
                        placeholder="Select one"
                        value={criteria.guest}
                        onChange={(e) =>
                            setCriteria({ ...criteria, guest: e.target.value })
                        }
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
            </div>
        </>
    );
}

export default SearchFormMain;
