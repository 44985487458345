import React from "react";

function Loading() {
    return (
        // <div className="fixed top-0 right-0 h-screen w-screen z-50 flex justify-center items-center">
        //     <div className="animate-spin rounded-full h-40 w-40 border-t-2 border-b-2 border-gray-900"></div>
        // </div>


        // <div class="fullpage-loader">
        //     <div class="loading-position">
        //         <div class="spinner-border" role="status">
        //             <span class="sr-only">Loading...</span>
        //         </div>
        //     </div>
        // </div>

        <div className="loading">Loading&#8230;</div>
    );
}

export default Loading;
