import React from "react";

function Footer() {
    return (
        <footer className="footer-bg footer-p">
            <div className="footer-top  pt-90 pb-40" style={{backgroundColor: '#644222', backgroundImage: `url(https://hotel.patrasdev.co.id/smk/images/footer-bg.png)`}}>
                <div className="container">
                    <div className="row justify-content-between">
                          <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title mb-30">
                                   <a href="/">
                                        <img src="https://dagang.patrasdev.co.id/gambar/profil/bR0dMHwy6BlbfztmkUUFdi1NJsZn5ogYgSMHu5nA.png" alt="img" style={{height: 60, width: 60}}/>
                                        <h3 style={{display: 'inline-block', fontSize: 30, letterSpacing: 3, fontWeight: '700', color: '#fff', lineHeight: 1, verticalAlign: 'middle', margin: 0, marginLeft: 10}}>
                                            HOTEL
                                            <span style={{display: 'block', fontSize: 15, lineHeight: 1, color: '#fff', letterSpacing: 0.2, marginTop: 10, textTransform: 'uppercase'}}>SMKN 2 Seririt</span>
                                        </h3>
                                    </a>
                                </div>
                                <div className="f-contact">
                                    <p style={{textAlign: 'justify'}}>
                                    SMK Negeri 2 Seririt merupakan sebuah sekolah menengah kejuruan yang berdiri sejak 3 Mei  tahun 2007. Sekolah ini terletak di Jalan Yudistira, Desa Banjarasem, kec. Seririt, Kabupaten Buleleng, Provinsi Bali.
                                    </p>
                                </div>
                                <div className="footer-social">                                    
                                    <a href="https://www.facebook.com/smkn2seririt/"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="https://www.instagram.com/smkn2seririt/"><i className="fab fa-instagram"></i></a>
                                    <a href="https://www.youtube.com/channel/UCdHC3eWUF9pmA1pEW-Wu5sQ"><i className="fab fa-youtube"></i></a>
                                </div> 
                            </div>
                        </div>
						<div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>Our Links</h2>
                                </div>
                                <div className="footer-link">
                                    <ul>                                        
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#"> Hotel</a></li>
                                        <li><a href="#"> Room </a></li>
                                        <li><a href="/contact"> Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                    <h2>Contact</h2>
                                </div>
                                <div className="f-contact">
                                    <ul>
                                    <li>
                                        <i className="icon fal fa-phone"></i>
                                        <span>Phone<br></br>0362 3361428</span>
                                    </li>
                                   <li><i className="icon fal fa-envelope"></i>
                                        <span>
                                            <a href="mailto:smknegeriseririt2@gmail.com">Email</a>
                                       <br></br>
                                       <a href="mailto:smknegeriseririt2@gmail.com">smknegeriseririt2@gmail.com</a>
                                       </span>
                                    </li>
                                    <li>
                                        <i className="icon fal fa-map-marker-check"></i>
                                        <span>Address<br></br> Jl. Yudistira No.Desa, Banjar Asem, Kec. Seririt</span>
                                    </li>
                                    
                                </ul>
                                    
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-lg-12 col-md-12">                         
                           Copyright &copy; 2023 SMKN 2 Seririt. All rights reserved.         
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
