import React from "react";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import BookForm from "./BookForm";

function Book() {
    return (
        <>
            <Header />
                <main>
                    <section className="breadcrumb-area d-flex align-items-center" style={{backgroundImage:`url(http://placehold.it/1920x450?text=banner)`}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-center">
                                        <div className="breadcrumb-title">
                                            <h2>Booking</h2>    
                                            <div className="breadcrumb-wrap">
                                    
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Booking</li>
                                            </ol>
                                        </nav>
                                    </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>

                    <BookForm />
                </main>
            <Footer />
        </>
    );
}

export default Book;
