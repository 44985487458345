import React from "react";

function ClientReviewCard({ avatar, name, location, rating, review }) {
    return (
        <div className="col-lg-6">
        <div className="testimonial-active">
        <div className="single-testimonial">
            <div className="testi-author">
                <img src={avatar} alt="img" style={{width: 80, height: 80, borderRadius: '50%'}}/>
                <div className="ta-info">
                    <h6>{name}</h6>
                    <span>{location}</span>
                </div>
            </div>
            <div className="review-icon">
                {Array(rating)
                    .fill()
                    .map((x, i) => (
                        <i className="fas fa-star fa-xs" key={i} style={{color: 'yellow'}}></i>
                ))}
            </div>
            <p>“{review}”.</p>
            
            <div className="qt-img">
                <img src="smk/images/qt-icon.png" alt="img" />
            </div>
        </div>
        </div>
        </div>
    );
}

export default ClientReviewCard;
