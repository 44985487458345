import React from "react";
import ClientReviewCard from "./ClientReviewCard";
// import TitleSection from "../Global/TitleSection";

function ClientReviews() {
    return (
        <section className="testimonial-area pt-120 pb-90 p-relative fix" style={{backgroundImage: `url(smk/images/testimonial-bg.png)`, backgroundSize: 'cover'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title center-align mb-50 text-center">
                            <h5>Testimonial</h5>
                            <h2>What Our Clients Says</h2>
                            <p>Proin consectetur non dolor vitae pulvinar. Pellentesque sollicitudin dolor eget neque viverra, sed interdum metus interdum. Cras lobortis pulvinar dolor, sit amet ullamcorper dolor iaculis vel</p>
                        </div>
                    
                    </div>

                        <ClientReviewCard
                            avatar="http://placehold.it/80x80?text=user"
                            name="Ahmed Hamada"
                            location="London - UK"
                            rating={5}
                            review="Lorem ipsum, dolor sit amet consectetur adipisicing
                                    consectetur adipisicing elit. Dolor, explicabo
                                    cupiditate. Alias, sequiLorem ipsum, dolor sit amet consectetur adipisicing
                                    consectetur adipisicing elit. Dolor, explicabo
                                    cupiditate. Alias, sequi"
                        />

                        <ClientReviewCard
                            avatar="http://placehold.it/80x80?text=user"
                            name="Alise Jonas"
                            location="Paris - France"
                            rating={5}
                            review="Lorem ipsum, dolor sit amet consectetur adipisicing
                                    consectetur adipisicing elit. Dolor, explicabo
                                    cupiditate. Alias, sequiLorem ipsum, dolor sit amet consectetur adipisicing
                                    consectetur adipisicing elit. Dolor, explicabo
                                    cupiditate. Alias, sequi"
                        />

                        <ClientReviewCard
                            avatar="http://placehold.it/80x80?text=user"
                            name="Jan Bjronsson"
                            location="Stockholm - Sweden"
                            rating={5}
                            review="Lorem ipsum, dolor sit amet consectetur adipisicing
                                    consectetur adipisicing elit. Dolor, explicabo
                                    cupiditate. Alias, sequiLorem ipsum, dolor sit amet consectetur adipisicing
                                    consectetur adipisicing elit. Dolor, explicabo
                                    cupiditate. Alias, sequi"
                        />
                        </div>
                    </div>
        </section>
    );
}

export default ClientReviews;
