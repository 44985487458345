import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useSecureLs from "../Global/useSecureLs";
import SecureLS from "secure-ls";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Header({ hotelId }) {
    useEffect(() => {
        let _firstLogin = localStorage.getItem("first_login");
        if (_firstLogin === "true" && state.auth.isAuthenticated) {
            setFirstLogin(true);
        }
    }, []); // eslint-disable-line

    const [dropDown, setDropDown] = useState(false);
    const state = useSelector((state) => state);
    const [id] = useSecureLs("user_id");
    let ls = new SecureLS({ encodingType: "aes", isCompression: false });
    const [firstLogin, setFirstLogin] = useState(false);
    return (
        <>
            <header className="header-area header-three">  
                {/* <div className="header-top second-header d-none d-md-block">
                    <div className="container">
                        <div className="row align-items-center">      
                            <div className="col-lg-10 col-md-10 d-none d-lg-block">
                                <div className="header-cta">
                                    <ul>                                   
                                        <li>
                                            <i className="far fa-clock"></i>
                                            <span>Mon - Fri: 9:00 - 19:00/ Closed on Weekends</span>
                                        </li>
                                        <li>
                                            <i className="far fa-mobile"></i>
                                            <strong>0362 3361428</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        
                            <div className="col-lg-2 col-md-2 d-none d-lg-block text-right">
                                <div className="header-social">
                                    <span>
                                        <a href="#" title="Facebook"><i className="fab fa-facebook-f"></i></a>
                                        <a href="#" title="LinkedIn"><i className="fab fa-instagram"></i></a>               
                                        <a href="#" title="Twitter"><i className="fab fa-twitter"></i></a>
                                        <a href="#" title="Twitter"><i className="fab fa-youtube"></i></a>
                                    </span>                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>		 */}
                <div id="header-sticky" className="menu-area">
                    <div className="container">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2">
                                    <div className="">
                                        <a href="/">
                                            <img src="https://dagang.patrasdev.co.id/gambar/profil/bR0dMHwy6BlbfztmkUUFdi1NJsZn5ogYgSMHu5nA.png" alt="img" style={{height: 50, width: 50}}/>
                                            <h3 style={{display: 'inline-block', fontSize: 20, letterSpacing: 3, fontWeight: '700', color: '#fff', lineHeight: 1, verticalAlign: 'middle', margin: 0, marginLeft: 10}}>
                                                HOTEL
                                                <span style={{display: 'block', fontSize: 10, lineHeight: 1, color: '#fff', letterSpacing: 0.2, marginTop: 5, textTransform: 'uppercase'}}>SMKN 2 Seririt</span>
                                            </h3>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8">
                                    <div className="main-menu text-center">
                                        <nav id="mobile-menu">
                                            <ul>
                                                <li><a href="/">Home</a></li>                                  
                                                <li><a href="/">Hotel</a></li>                        
                                                <li><a href="/">Room</a></li>
                                                <li><a href="/contact">Contact</a></li>                 
                                            </ul>
                                        </nav>
                                    </div>
                                </div>  
                                <>
                                {!state.auth.isAuthenticated ? (
                                    <div className="col-xl-2 col-lg-2 d-none d-lg-block">
                                        <Link to="/login" className="top-btn mt-10 mb-10">Login </Link>
                                    </div>
                                ) : (
                                    <div className="col-xl-1 col-lg-1 d-none d-lg-block text-right">
                                        <div className="search-top">
                                            <ul>
                                                <li>
                                                    <div className="bar-humburger">
                                                        <a href="#" 
                                                            className="menu-tigger"
                                                            onClick={() => {
                                                                setDropDown(!dropDown);
                                                            }}
                                                            onMouseOver={() => {
                                                                localStorage.setItem("first_login", false);
                                                                setFirstLogin(false);
                                                            }}
                                                        >
                                                            <i className={`fal fa-user cursor-pointer ${
                                                                    firstLogin && "animate-bounce"
                                                                }`}>
                                                            </i>
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                </>

                                <div className="col-12">
                                    <div className="mobile-menu"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {!state.auth.is_admin ? (
                <div className="offcanvas-menu active"
                    onClick={() => setDropDown(!dropDown)}
                    onMouseLeave={() => setDropDown(false)}
                    style={
                        !dropDown
                            ? { display: "none" }
                            : { display: "block" }
                    }
                >
                    <span className="menu-close"><i className="fal fa-times"></i></span>

                    <div id="cssmenu3" className="menu-one-page-menu-container">
                        <ul id="menu-one-page-menu-2" className="menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to={`/user-profile/${id}`}>
                                    <i className="fal fa-user" style={{margin: 10}}></i>
                                    Profile
                                </Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to={`/user-bookings/${id}`}>
                                    <i className="fal fa-ticket" style={{margin: 10}}></i>
                                    Your Bookings
                                </Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to={`/user-reviews/${id}`}>
                                    <i className="fal fa-user-md" style={{margin: 10}}></i>
                                    Your Reviews
                                </Link>
                            </li>
                        </ul>
                    </div>  
                    
                    <div id="cssmenu2" className="menu-one-page-menu-container mt-100">
                        <ul id="menu-one-page-menu-1" className="menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link
                                    onClick={(e) => {
                                        ls.remove("token");
                                        ls.remove("user_id");
                                        ls.remove("is_admin");
                                        window.location.replace("/");
                                    }}
                                >
                                    <i className="fal fa-lock" style={{margin: 10}}></i>
                                    Logout
                                </Link>
                            </li>
                        </ul>
                    </div>                
                </div>
            ) : (
                <div className="offcanvas-menu active"
                    onClick={() => setDropDown(!dropDown)}
                    onMouseLeave={() => setDropDown(false)}
                    style={
                        !dropDown
                            ? { display: "none" }
                            : { display: "block" }
                    }
                >
                    <span className="menu-close"><i className="fal fa-times"></i></span>

                    <div id="cssmenu3" className="menu-one-page-menu-container">
                        <ul id="menu-one-page-menu-2" className="menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to={`/admin-profile/${id}`}>
                                    <i className="fal fa-user" style={{margin: 10}}></i>
                                    Profile
                                </Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/hotel-management">
                                    <i className="fal fa-home" style={{margin: 10}}></i>
                                    Hotel Management
                                </Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/room-management">
                                    <i className="fal fa-bed-alt" style={{margin: 10}}></i>
                                    Room Management
                                </Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/booking-management">
                                    <i className="fal fa-book-open" style={{margin: 10}}></i>
                                    Booking Management
                                </Link>
                            </li>
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link to="/review-management">
                                    <i className="fal fa-user-md-chat" style={{margin: 10}}></i>
                                    Review Management
                                </Link>
                            </li>
                        </ul>
                    </div>  
                    
                    <div id="cssmenu2" className="menu-one-page-menu-container mt-100">
                        <ul id="menu-one-page-menu-1" className="menu">
                            <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                <Link
                                    onClick={(e) => {
                                        ls.remove("token");
                                        ls.remove("user_id");
                                        ls.remove("is_admin");
                                        window.location.replace("/");
                                    }}
                                >
                                <i className="fal fa-lock" style={{margin: 10}}></i>
                                     Logout
                                </Link>
                            </li>
                        </ul>
                    </div>                
                </div>
            )}
            <div className="offcanvas-overly"></div>
        </>
    );
}

Header.defaultProps = {
    hotelId: 1
};

export default Header;
