import React, { useState, useEffect } from "react";
import Loading from "../Global/Loading";
import { signUp } from "../../redux/actions/auth";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ErrorMessage from "../Global/ErrorMessage";
import useSecureLs from "../../Components/Global/useSecureLs";

function SignupForm() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [token, setToken] = useSecureLs("token"); // eslint-disable-line
    const [userId, setUserId] = useSecureLs("user_id"); // eslint-disable-line
    const [isAdmin, setIsAdmin] = useSecureLs("is_admin"); // eslint-disable-line

    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: ""
    });

    const onSubmitHandler = (e) => {
        e.preventDefault();
        signUp(dispatch, user, setToken, setUserId, setIsAdmin);
    };

    let history = useHistory();
    useEffect(() => {
        if (state.auth.isAuthenticated) {
            history.push("/");
        } else {
            history.push("/signup");
        }
    }, [state.auth.token]); // eslint-disable-line

    useEffect(() => {
        document.title = `Signup`;
    }, []); // eslint-disable-line
    return (
        
        <section id="contact" className="contact-area after-none contact-bg pt-120 pb-20 p-relative fix">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 order-2">
                        <div className="contact-bg02">
                            {state.auth.loading && <Loading />}

                            {state.auth.success === false && (
                                <ErrorMessage errors={state.auth.errors} />
                            )}
                            <div className="section-title center-align mb-40 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                <img className="mb-3" src="https://dagang.patrasdev.co.id/gambar/profil/bR0dMHwy6BlbfztmkUUFdi1NJsZn5ogYgSMHu5nA.png" width={100} />
                                <h3>Welcome to </h3>
                                <h4>SMK N 2 Seririt Hotel 👋</h4>
                                <small>Create a free account in order to access our services</small>
                            </div>                               
                            <form onSubmit={onSubmitHandler} method="post" className="contact-form mt-30">
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <input 
                                                id="first_name"
                                                type="text"
                                                name="first_name"
                                                aria-required="true"
                                                placeholder="First Name"
                                                onChange={(e) =>
                                                    setUser({ ...user, first_name: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <input 
                                                id="last_name"
                                                type="text"
                                                name="last_name"
                                                placeholder="Last Name"
                                                aria-required="true"
                                                onChange={(e) =>
                                                    setUser({ ...user, last_name: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>		
                                    <div className="col-lg-12 col-md-12">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <input 
                                                type="text" 
                                                id="email" 
                                                name="email" 
                                                placeholder="Email" 
                                                required 
                                                onChange={(e) =>
                                                    setUser({ ...user, email: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>		
                                    <div className="col-lg-12 col-md-12">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <input 
                                                type="password" 
                                                id="password" 
                                                name="password" 
                                                placeholder="Password" 
                                                required 
                                                onChange={(e) =>
                                                    setUser({ ...user, password: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="slider-btn">                                          
                                            <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s"><span>Register</span></button>			
                                        </div>
                                        <div className="mt-5">
                                            <h5>Already have an account? <Link to="/login">Login</Link></h5>
                                        </div>                     
                                    </div>
                                </div>
                            </form>                            
                        </div>    
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SignupForm;
