import React from "react";


function PaymentForm({ booking, setBooking, maxLengthCheck, onSubmitHandler }) {
    return (
        <>
            <div className="section-title center-align mb-10 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                <h4>
                    Step 2: Payment Details
                </h4>
            </div>                               
            <form onSubmit={onSubmitHandler} method="post" className="contact-form mt-30">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-name mb-20">                                    
                            <input 
                                id="first-name"
                                placeholder="First Name"
                                type="text"
                                aria-required="true"
                                value={booking.full_name[0]}
                                onChange={(e) =>
                                    setBooking({
                                        ...booking,
                                        full_name: [
                                            e.target.value,
                                            booking.full_name[1]
                                        ]
                                    })
                                }
                            />
                        </div>                               
                    </div>

                    <div className="col-lg-6 col-md-6">                               
                        <div className="contact-field p-relative c-subject mb-20">                                   
                            <input 
                                id="last-name"
                                placeholder="Last Name"
                                type="text"
                                aria-required="true"
                                value={booking.full_name[1]}
                                onChange={(e) =>
                                    setBooking({
                                        ...booking,
                                        full_name: [
                                            booking.full_name[0],
                                            e.target.value
                                        ]
                                    })
                                }
                            />
                        </div>
                    </div>		
                    <div className="col-lg-6 col-md-6">                               
                        <div className="contact-field p-relative c-subject mb-20">                                   
                            <input 
                                id="card-number"
                                type="text"
                                maxLength="16"
                                onInput={maxLengthCheck}
                                placeholder="Card Number"
                                aria-required="true"
                                value={booking.number}
                                onChange={(e) =>
                                    setBooking({
                                        ...booking,
                                        number: e.target.value.replace(/\D/, "")
                                    })
                                }
                            />
                        </div>
                    </div>	
                    <div className="col-lg-3 col-md-3">                               
                        <div className="contact-field p-relative c-subject mb-20">                                   
                            <input 
                                id="expiry-date"
                                type="number"
                                min="01"
                                max="12"
                                maxLength="2"
                                onInput={maxLengthCheck}
                                placeholder="MM"
                                aria-required="true"
                                value={booking.exp_month}
                                onChange={(e) =>
                                    setBooking({
                                        ...booking,
                                        exp_month: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-md-3">                               
                        <div className="contact-field p-relative c-subject mb-20">                                   
                            <input 
                                type="number"
                                min={new Date()
                                    .getFullYear()
                                    .toString()
                                    .substr(2, 2)}
                                max={
                                    parseInt(
                                        new Date()
                                            .getFullYear()
                                            .toString()
                                            .substr(2, 2)
                                    ) + 20
                                }
                                maxLength="2"
                                onInput={maxLengthCheck}
                                placeholder="YY"
                                value={booking.exp_year}
                                onChange={(e) =>
                                    setBooking({
                                        ...booking,
                                        exp_year: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>	

                    <div className="col-lg-12 col-md-12">                               
                        <div className="contact-field p-relative c-subject mb-20">                                   
                            <input 
                                id="cvv"
                                type="password"
                                maxLength="3"
                                onInput={maxLengthCheck}
                                placeholder="CVV*"
                                aria-required="true"            
                                value={booking.cvc}
                                onChange={(e) =>
                                    setBooking({
                                        ...booking,
                                        cvc: e.target.value
                                    })
                                }
                            />
                        </div>
                    </div>	
                    <div className="col-lg-12">
                        <div className="section-title center-align mb-40 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                            <h4>
                                Step 3: Place Order
                            </h4>
                        </div>    
                        <div className="slider-btn">                                          
                            <button className="btn ss-btn" type="submit" data-animation="fadeInRight" data-delay=".8s"><span>BOOK</span></button>				
                        </div>                             
                    </div>
                </div>
            </form>     
        </>
    );
}

export default PaymentForm;
