import React, { useEffect, useState } from "react";
import CurrencyFormat from 'react-currency-format';

function RoomCard({ room }) {
    const [image, setImage] = useState(null);
    useEffect(() => {
        try {
            let images = room && room.image.split(",");
            room && setImage(images[0]);
        } catch (error) {}
    }, [room]); // eslint-disable-line
    return (
        <>
            <div className="col-xl-4 col-md-6">
                <div className="single-services ser-m mb-30">
                    <div className="services-thumb">
                        <a className="gallery-link popup-image" href={
                            room && room.image && typeof image === "string"
                                ? `${process.env.REACT_APP_BASE_URL}/${image}`
                                : "http://placehold.it/300x300?text=room image"
                        }>
                        <img src={
                            room && room.image && typeof image === "string"
                                ? `${process.env.REACT_APP_BASE_URL}/${image}`
                                : "http://placehold.it/300x300?text=room image"
                        } alt="img"/>
                        </a>
                    </div>
                    <div className="services-content"> 
                        <div className="day-book">
                            <ul>
                                <li style={{textDecoration: 'line-through'}}>
                                    <CurrencyFormat style={{marginBottom: 20}} value={parseFloat(room.price) + 100} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} />
                                </li>
                                <li>
                                    <a href={`/room/${room.id}`}>
                                        <CurrencyFormat style={{marginBottom: 20}} value={room.price} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <h4><a href={`/room/${room.id}`}>{room.name}</a></h4> 
                        <p style={{textAlign: 'justify'}}>{room.description}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RoomCard;
