import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MoreImage from "./MoreImage";
import useSecureLs from "../Global/useSecureLs";
import CurrencyFormat from 'react-currency-format';

function RoomHero({ room }) {
    const [room_id, setRoomId] = useSecureLs("room_id"); // eslint-disable-line
    const [room_name, setRoomName] = useSecureLs("room_name"); // eslint-disable-line
    const [room_price, setRoomPrice] = useSecureLs("room_price"); // eslint-disable-line
    const [room_image, setRoomImage] = useSecureLs("room_image"); // eslint-disable-line

    const [_image, setImage] = useState("");

    useEffect(() => {
        try {
            let images = room && room.image.split(",");
            room && setImage(images[0]);
        } catch (error) {}
        setRoomId(room.id);
        setRoomName(room.name);
        setRoomPrice(room.price);
        setRoomImage(_image);
    }, [room, _image]); // eslint-disable-line
    return (
        <>
            <section className="breadcrumb-area d-flex align-items-center" style={{backgroundImage:`url(https://images.unsplash.com/photo-1578683010236-d716f9a3f461?auto=format&fit=crop&q=80&w=3870&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center">
                                <div className="breadcrumb-title">
                                    <h2>{room.name}</h2>
                                    <div className="breadcrumb-wrap">
                            
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Room Details</li>
                                    </ol>
                                </nav>
                            </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <div className="about-area5 about-p p-relative">
                <div className="container pt-120 pb-40">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 order-1">
                            <div className="service-detail">

                                <div className="two-column">
                                    <div className="row">
                                        <div className="image-column col-xl-6 col-lg-12 col-md-12">
                                            <figure className="image"><img src={
                                                    _image
                                                        ? `${process.env.REACT_APP_BASE_URL}/${_image}`
                                                        : "http://placehold.it/406x499?text=image not available"
                                                } alt=""/></figure>
                                        </div>
                                        
                                        <MoreImage images={room && room.image} />
                                    </div>
                                </div>
                                
                                <div className="content-box">
                                    <div className="row align-items-center mb-50">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="price">
                                                <h2>{room && room.name}</h2>
                                                <CurrencyFormat style={{marginBottom: 0}} value={room && room.price} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} />/Night
                                            </div>
                                        </div>
                                        {/* <div class="col-lg-6 text-right">
                                            <img src="https://htmldemo.zcubethemes.com/riorelax/img/testimonial/review-icon.png" alt="img" />
                                        </div> */}
                                    </div>

                                    <p>{room && room.description}</p>

                                    {room && room.features.length > 0 && (
                                        <>
                                            <h3>Room Feature.</h3>
                                            <ul className="room-features d-flex align-items-center">
                                                {room.features.slice(0, 5).map((f, i) => {
                                                    return (
                                                        <li key={i}>
                                                            <i className={`${f.icon}`}></i>
                                                            {f.name}
                                                        </li>
                                                    );
                                                })}
                                            </ul>

                                            {/* <ul className="room-features d-flex align-items-center">
                                                {room.features.slice(5, 10).map((f, i) => {
                                                    return (
                                                        <li className="mt-2" key={i}>
                                                            <i className="fas fa-star fa-xs text-yellow-600 mr-2"></i>
                                                            {f.name}
                                                        </li>
                                                    );
                                                })}
                                            </ul> */}
                                        </>
                                    )}

                                    <div className="mb-50 text-center">
                                        <Link to="/book" className="btn ss-btn">Book This Room</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default RoomHero;
