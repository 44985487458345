import React, { useState, useEffect } from "react";
import { logIn } from "../../redux/actions/auth";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../Global/Loading";
import ErrorMessage from "../Global/ErrorMessage";
import useSecureLs from "../../Components/Global/useSecureLs";
import { Link } from "react-router-dom";

function LoginForm() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    const [user, setUser] = useState({
        email: "",
        password: ""
    });
    const [token, setToken] = useSecureLs("token"); // eslint-disable-line
    const [userId, setUserId] = useSecureLs("user_id"); // eslint-disable-line
    const [isAdmin, setIsAdmin] = useSecureLs("is_admin"); // eslint-disable-line

    const onSubmitHandler = (e) => {
        e.preventDefault();
        logIn(dispatch, user, setToken, setUserId, setIsAdmin);
    };
    let history = useHistory();
    useEffect(() => {
        if(state.auth.is_admin) {
            state.auth.isAuthenticated && history.push("/");
        };
    }, [state.auth.isAuthenticated]); // eslint-disable-line

    useEffect(() => {
        document.title = `Login`;
    }, []); // eslint-disable-line
    return (
        <section id="contact" className="contact-area after-none contact-bg pt-120 pb-20 p-relative fix">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 order-2">
                        <div className="contact-bg02">
                            {state.auth.loading && <Loading />}
                            {state.auth.success === false && (
                                <ErrorMessage errors={state.auth.errors} />
                            )}
                            <div className="section-title center-align mb-40 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                <img className="mb-3" src="https://dagang.patrasdev.co.id/gambar/profil/bR0dMHwy6BlbfztmkUUFdi1NJsZn5ogYgSMHu5nA.png" width={100} />
                                <h3>Welcome to </h3>
                                <h4>SMK N 2 Seririt Hotel 👋</h4>
                                <small>Please sign-in to your account and start the adventure</small>
                            </div>                               
                            <form onSubmit={onSubmitHandler} method="post" className="contact-form mt-30">
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <input 
                                                type="text" 
                                                id="email" 
                                                name="email" 
                                                placeholder="Email" 
                                                required 
                                                onChange={(e) =>
                                                    setUser({ ...user, email: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>		
                                    <div className="col-lg-12 col-md-12">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <input 
                                                type="password" 
                                                id="password" 
                                                name="password" 
                                                placeholder="Password" 
                                                required 
                                                onChange={(e) =>
                                                    setUser({ ...user, password: e.target.value })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="slider-btn">                                          
                                            <button className="btn ss-btn" data-animation="fadeInRight" type="submit" data-delay=".8s"><span>Login</span></button>			
                                        </div>
                                        <div className="mt-5">
                                            <h5>Don't have an account yet? <Link to="/signup">Registration</Link></h5>
                                        </div>                    
                                    </div>
                                </div>
                            </form>                            
                        </div>    
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LoginForm;
