import React from "react";
import HotelItem from "./HotelItem";
import { search } from "../../redux/actions/search";

import { useSelector, useDispatch } from "react-redux";
import Pagination from "../Global/Pagination";

function HotelsList({ criteria, _ref }) {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    return (
        <div className="col-lg-8 col-md-12 col-sm-12 order-1" ref={_ref}>
            <div className="service-detail">
                {/* {state.search.pagination.last_page !== 1 && (
                    <Pagination
                        paginate={state.search}
                        method={search}
                        params={[dispatch, criteria]}
                    />
                )} */}

                <div className="row">

                    {state &&
                        state.search.hotels.map((hotel, i) => {
                            return <HotelItem key={i} hotel={hotel} />;
                        })}
                </div>

                {state.search.pagination.last_page !== 1 && (
                    <Pagination
                        paginate={state.search}
                        method={search}
                        params={[dispatch, criteria]}
                    />
                )}
            </div>
        </div>
    );
}

export default HotelsList;
