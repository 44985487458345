import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Slider from "./Slider";
import { getHotelImages } from "../../redux/actions/hotels";

function Welcome() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    useEffect(() => {
        getHotelImages(dispatch);
    }, []); // eslint-disable-line

    return (
        <>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
            <div className="animations-02"><img src="smk/images/an-img-02.png" alt="contact-bg-an-02"/></div>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                            <img style={{width: 470, height: 590}} src="https://images.unsplash.com/photo-1549294413-26f195200c16?auto=format&fit=crop&q=80&w=2400&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="img"/>
                            <div className="about-icon">
                                <img style={{width: 370, height: 413}} src="https://images.unsplash.com/photo-1505576391880-b3f9d713dc4f?auto=format&fit=crop&q=80&w=1870&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="img"/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="about-content s-about-content  wow fadeInRight  animated pl-30" data-animation="fadeInRight" data-delay=".4s">
                            <div className="about-title second-title pb-25">  
                                <h5>About Us</h5>
                                <h2>Most Safe &amp; Rated Hotel In SMK.</h2>                                   
                            </div>
                            <p>Morbi tortor urna, placerat vel arcu quis, fringilla egestas neque. Morbi sit amet porta erat, quis rutrum risus. Vivamus et gravida nibh, quis posuere felis. In commodo mi lectus, Integer ligula lorem, finibus vitae lorem vitae tincidunt dolor consequat quis.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="service-details2" className="pt-120 pb-90 p-relative" style={{backgroundColor: "#f7f5f1"}}>
            <div className="animations-01"><img src="smk/images/an-img-01.png" alt="an-img-01" /></div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="section-title center-align mb-50 text-center">
                            <h5>Comfortable Stay, Lots of Choices</h5>
                            <h2>
                                The Hotel
                            </h2>
                            <p>Whether you want to make me time or go out for a party, feel free to choose a place to stay that suits your needs.</p>
                        </div>
                        
                    </div>
                    <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                <div className="services-icon2">
                                    <img src="smk/images/fe-icon01.png" alt="img" />
                                </div>
                                <div className="services-08-thumb">
                                    <img src="smk/images/fe-icon01.png" alt="img" />
                                </div>
                                <div className="services-08-content">
                                    <h3><a href="#"> Qulity Room</a></h3>
                                    <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-lg-4 col-md-6">                                  
                            <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                    <img src="smk/images/fe-icon04.png" alt="img" />
                                </div>
                                <div className="services-08-thumb">
                                <img src="smk/images/fe-icon04.png" alt="img" />
                                </div>
                                <div className="services-08-content">
                                    <h3><a href="#">Privet Beach</a></h3>
                                    <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                    <img src="smk/images/fe-icon05.png" alt="img" />
                                </div>
                                <div className="services-08-thumb">
                                    <img src="smk/images/fe-icon05.png" alt="img" />
                                </div>
                                <div className="services-08-content">
                                    <h3><a href="#">Best Accommodation</a></h3>
                                    <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                    <div className="services-icon2">
                                    <img src="smk/images/fe-icon06.png" alt="img" />
                                </div>
                                <div className="services-08-thumb">
                                    <img src="smk/images/fe-icon06.png" alt="img" />
                                </div>
                                <div className="services-08-content">
                                    <h3><a href="#"> Wellness &amp; Spa</a></h3>
                                    <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                <div className="services-icon2">
                                    <img src="smk/images/fe-icon07.png" alt="img" />
                                </div>
                                <div className="services-08-thumb">
                                <img src="smk/images/fe-icon07.png" alt="img" />
                                </div>
                                <div className="services-08-content">
                                    <h3><a href="https://dagang.patrasdev.co.id">Restaurants &amp; Bars</a></h3>
                                    <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-lg-4 col-md-6">
                            <div className="services-08-item mb-30">
                                <div className="services-icon2">
                                    <img src="smk/images/fe-icon08.png" alt="img" />
                                </div>
                                <div className="services-08-thumb">
                                    <img src="smk/images/fe-icon08.png" alt="img" />
                                </div>
                                <div className="services-08-content">
                                    <h3><a href="#">Special Offers</a></h3>
                                    <p>Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default Welcome;
