import React from "react";

function TitleSection({ title }) {
    return (
        // <div className="my-8 md:px-16 xl:px-24 font-serif">
        //     <h2 className="text-4xl text-center font-semibold text-gray-800 ">
        //         {title}
        //     </h2>
        //     <div className="text-center">
        //         <i className="fas fa-star fa-xs"></i>
        //         <i className="fas fa-star fa-xs"></i>
        //         <i className="fas fa-star fa-xs"></i>
        //     </div>
        // </div>
        <section className="testimonial-area p-relative fix">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row justify-content-center">
                            <div className="col-xl-12">    
                                <div className="section-title center-align mb-50 text-center">
                                    <h5>{title}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TitleSection;
