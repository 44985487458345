import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchData } from "../../redux/actions/search";
import { Link } from "react-router-dom";

function Hero() {
    const [initSearchData, setinitSearchData] = useState({
        check_in: "",
        check_out: "",
        city: "",
        guest: 1
    });
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    useEffect(() => {
        getSearchData(dispatch);
    }, []); // eslint-disable-line

    return (
        <section id="home" className="slider-area fix p-relative">
            <div className="slider-active" style={{backgroundColor: '#101010'}}>
                <div className="single-slider slider-bg d-flex align-items-center" style={{backgroundImage: `url(https://images.unsplash.com/photo-1566073771259-6a8506099945?auto=format&fit=crop&q=80&w=3870&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`, backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-7 col-md-7">
                                <div className="slider-content s-slider-content mt-100">
                                    <h2 data-animation="fadeInUp" data-delay=".4s">Enjoy A Luxuary Experience</h2>
                                    <p data-animation="fadeInUp" data-delay=".6s">Book cheap hotels online with promotional prices</p>
                                    
                                    {/* <div className="slider-btn mt-30 mb-105">     
                                        <a href="contact.html" className="btn ss-btn active" data-animation="fadeInLeft" data-delay=".4s">Visit Our Shop</a>
                                    </div>         */}
                                                        
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 p-relative">
                                <div className="booking-area booking-area2 p-relative">
                                    <div className="container">
                                        <form action="#" className="contact-form">
                                            <div className="row align-items-center">
                                                <div className="col-lg-12">
                                                    <div className="section-title center-align mb-30">
                                                        <h2>
                                                            Book A Room
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6"> 
                                                    <div className="contact-field p-relative c-name mb-30">  
                                                        <label><i className="fal fa-badge-check"></i> Check In Date</label>
                                                        <input 
                                                            type="date" 
                                                            id="check-in"
                                                            value={initSearchData.check_in}
                                                            name="date"
                                                            onChange={(e) =>
                                                                setinitSearchData({
                                                                    ...initSearchData,
                                                                    check_in: e.target.value
                                                                })
                                                            }
                                                        />
                                                    </div>      
                                                </div>
                                                <div className="col-lg-6 col-md-6"> 
                                                    <div className="contact-field p-relative c-name mb-30">  
                                                        <label><i className="fal fa-times-octagon"></i> Check Out Date</label>
                                                        <input 
                                                            id='check-out' 
                                                            type="date" 
                                                            name="date"
                                                            value={initSearchData.check_out}
                                                            onChange={(e) =>
                                                                setinitSearchData({
                                                                    ...initSearchData,
                                                                    check_out: e.target.value
                                                                })
                                                            }
                                                        />
                                                    </div>         
                                                </div>
                                                <div className="col-lg-6 col-md-6"> 
                                                    <div className="contact-field p-relative c-name mb-30">  
                                                        <label><i className="fal fa-users"></i> City</label>
                                                        <select 
                                                            value={initSearchData.city}
                                                            onChange={(e) =>
                                                                setinitSearchData({
                                                                    ...initSearchData,
                                                                    city: e.target.value
                                                                })
                                                            }
                                                        >
                                                            <option value="sports-massage">All Cities</option>
                                                            {state &&
                                                            state.search.cities.map((city, i) => {
                                                                return (
                                                                    <option value={city} key={i}>
                                                                        {city}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>      
                                                </div>
                                                <div className="col-lg-6 col-md-6"> 
                                                    <div className="contact-field p-relative c-name mb-30">  
                                                        <label><i className="fal fa-baby"></i> Guests</label>
                                                        <select 
                                                            value={initSearchData.guest}
                                                            onChange={(e) =>
                                                                setinitSearchData({
                                                                    ...initSearchData,
                                                                    guest: e.target.value
                                                                })
                                                            }
                                                        >
                                                            <option>Select one</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                        </select>
                                                    </div>      
                                                </div>
                                                <div className="col-lg-12"> 
                                                    <div className="slider-btn mb-20 w-100">    
                                                        <Link className="btn ss-btn w-100" to={`/search/check_in=${initSearchData.check_in}&check_out=${initSearchData.check_out}&city=${initSearchData.city}&guest=${initSearchData.guest}`} data-animation="fadeInRight" data-delay=".8s">Check Availability</Link>
                                                    </div>     
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
