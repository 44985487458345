import React, { useEffect } from "react";
import UserBookingItem from "./UserBookingItem";
import { useSelector, useDispatch } from "react-redux";
import { getUserBookings } from "../../../redux/actions/bookings";
import { useParams } from "react-router-dom";
import Pagination from "../../Global/Pagination";
import Loading from "../../Global/Loading";

function UserBookingsList() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    let { id } = useParams();

    useEffect(() => {
        getUserBookings(dispatch, id, state.auth.token);
        document.title = `Bookings`;
    }, []); // eslint-disable-line

    return (
        <div className="section-title center-align mb-40 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
            <h5>
                Your Bookings
            </h5>
            {state.bookings.loading && <Loading />}
            {state.bookings.pagination.last_page !== 1 && (
                <Pagination
                    paginate={state.bookings}
                    params={[dispatch, id, state.auth.token]}
                    method={getUserBookings}
                    id={id}
                />
            )}

            {/* <div class="col-lg-12">
                <div class="product-details mb-30">
                    {state &&
                        state.bookings.allBookings.map((booking) => {
                            return (
                                <UserBookingItem
                                    key={booking.id}
                                    booking={booking}
                                />
                            );
                        })}

                    {state.bookings.allBookings.length === 0 && (
                        <div className=" mt-10">You have no bookings!</div>
                    )}
                </div>
            </div> */}

            <div className="row">
                {state &&
                    state.bookings.allBookings.map((booking) => {
                        return (
                            <UserBookingItem
                                key={booking.id}
                                booking={booking}
                            />
                        );
                    })}

                {state.bookings.allBookings.length === 0 && (
                    <div className=" mt-10">You have no bookings!</div>
                )}
            </div>

            {state.bookings.pagination.last_page !== 1 && (
                <Pagination
                    paginate={state.bookings}
                    params={[dispatch, id, state.auth.token]}
                    method={getUserBookings}
                    id={id}
                />
            )}
        </div>
    );
}

export default UserBookingsList;
