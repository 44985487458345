import React from "react";

function HotelHero({ hotel }) {
    let _star = [];
    if (hotel) {
        for (let i = 0; i < hotel.star; i++) {
            _star.push(
                <i className="fas fa-star fa-xs text-orange-400" key={i}></i>
            );
        }
    }
    return (
        <section className="breadcrumb-area d-flex align-items-center" style={{backgroundImage:`url(https://images.unsplash.com/photo-1578683010236-d716f9a3f461?auto=format&fit=crop&q=80&w=3870&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-12 col-lg-12">
                        <div className="breadcrumb-wrap text-center">
                            <div className="breadcrumb-title">
                                <h2>{hotel && hotel.name}</h2>    
                                <div className="breadcrumb-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">{hotel && hotel.address}</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Starting From{" "} Rp. {hotel && hotel.min_price}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HotelHero;
