import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import CurrencyFormat from 'react-currency-format';
export default class UserBookingItem extends Component {
    render() {
        const {
            id,
            name,
            image,
            city,
            price,
            check_in,
            check_out,
            created_at
        } = this.props.booking;
        return (
            <>
                <div className="col-xl-4" ref={(el) => (this.componentRef = el)}>
                    <div className="shop-thumb-tab mb-30">
                        <ul className="nav" id="myTab2" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab"
                                    aria-selected="true">
                                    <img src={
                                            image
                                                ? `${process.env.REACT_APP_BASE_URL}/${image}`
                                                : "http://placehold.it/300x300?text=no image available"
                                        } alt=""
                                    /> 
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-xl-8" ref={(el) => (this.componentRef = el)}>
                    <div className="product-details mb-30">
                        <div className="product-details-title">
                            <p>Booking ID : #{id}</p>
                            <h3>{name}</h3>
                            <h5>Check-in Date : {check_in}</h5>
                            <h5>Check-out Date : {check_out}</h5>
                            <div className="price details-price mb-10">
                                <CurrencyFormat style={{marginBottom: 20}} value={price} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} />
                            </div>
                            <p>Placed on : {new Date(created_at).toISOString().split("T")[0]}</p>
                        </div>
                         
                        <div className="product-details-action">
                            <ReactToPrint
                                documentTitle="test"
                                trigger={() => {
                                    return (
                                        <button
                                            href="#"
                                            className="btn btn-black btn-sm hover:underline focus:outline-none"
                                        >
                                            <i className="fas fa-print"></i> Print
                                        </button>
                                    );
                                }}
                                content={() => this.componentRef}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
