import React from "react";
import Header from "../Global/Header";
import Footer from "../Global/Footer";
import LoginForm from "./LoginForm";

function Login() {
    
    return (
        <>
            <Header />
                <main>
                    <LoginForm />
                </main>
            <Footer />
        </>
    );
}

export default Login;
