import React, { useState } from "react";
import EditReview from "./EditReview";
import Confirm from "../../Global/Confirm";

function UserReviewItem({
    review: { created_at, content, rating, name, city },
    review,
    i
}) {
    let _rating = "";
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [edit, setEdit] = useState(false);
    switch (rating) {
        case 10:
            _rating = "10/10 Amazing";
            break;
        case 9:
            _rating = "9/10 Great";
            break;
        case 8:
            _rating = "8/10 Very Good";
            break;
        case 7:
            _rating = "7/10 Good";
            break;
        case 6:
            _rating = "6/10 Fine";
            break;
        case 5:
            _rating = "5/10 Average";
            break;
        case 4:
            _rating = "4/10 Bad";
            break;
        case 3:
            _rating = "3/10 Very Bad";
            break;
        case 2:
            _rating = "2/10 Horrible";
            break;
        case 1:
            _rating = "1/10 Appaling";
            break;

        default:
            break;
    }
    return (
        <section class="inner-blog b-details-p pt-20">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="blog-details-wrap">
                            <div class="comment__wrap">
                                <div class="single__comment mb-35">
                                    <div class="comment-text">
                                        <div class="avatar-name">
                                            <h6>{name}</h6>
                                            <span>On {new Date(created_at).toLocaleDateString()}</span><br></br>
                                            <span>{city}</span><br></br>
                                            <span>{_rating}</span>
                                        </div>
                                        <p>{content}</p>
                                        <button
                                            onClick={() =>
                                                setConfirmDelete({
                                                    ...confirmDelete,
                                                    open: true
                                                })
                                            }
                                            className="comment-reply mt-15"
                                        >
                                            Delete
                                        </button>
                                        <button
                                            onClick={() =>
                                                setEdit({
                                                    ...edit,
                                                    open: true
                                                })
                                            }
                                            className="comment-reply mt-15"
                                        >
                                            Edit
                                        </button>
                                        {confirmDelete.open && (
                                            <Confirm
                                                confirmDelete={confirmDelete}
                                                setConfirmDelete={setConfirmDelete}
                                                review={review}
                                            />
                                        )}
                                        {edit.open && (
                                            <EditReview edit={edit} setEdit={setEdit} review={review} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default UserReviewItem;
