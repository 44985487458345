import React from "react";
import { Link } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';

function HotelCard({ hotel: { id, name, min_price, star, image, address, city } }) {
    return (
        <>
            <div className="col-xl-4 col-md-6 mb-30">
                <div className="single-services mb-30">
                    <div className="services-thumb">
                        <a className="gallery-link popup-image" href={
                                image
                                    ? `${process.env.REACT_APP_BASE_URL}/${image}`
                                    : "http://placehold.it/300x300?text=hotel"
                            }>
                        <img src={
                                image
                                    ? `${process.env.REACT_APP_BASE_URL}/${image}`
                                    : "http://placehold.it/300x300?text=hotel"
                            } alt="img" />
                        </a>
                    </div>
                    <div className="services-content"> 
                        <div className="day-book">
                            <ul>
                                {/* <li>
                                    <CurrencyFormat style={{marginBottom: 0}} value={min_price} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} />
                                </li> */}
                                <li><Link to={`/hotel/${id}`}>Selengkapnya</Link></li>
                            </ul>
                        </div>
                        <h4><Link to={`/hotel/${id}`}>{name}</Link></h4>
                        <p>{city}</p>
                        <p>{address}</p>
                        <div className="icon">
                            <ul>
                                <li>
                                    {Array(star)
                                        .fill()
                                        .map((x, i) => (
                                        <i
                                            className="fas fa-star fa-xs" 
                                            style={{color: 'yellow'}}
                                            key={i}
                                        ></i>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HotelCard;
