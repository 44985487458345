import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { search, getSearchData } from "../../../redux/actions/search";
import SearchFormMain from "./SearchFormMain";
import SearchFormStars from "./SearchFormStars";
import SearchFormPrice from "./SearchFormPrice";
import SearchFormFeatures from "./SearchFormFeatures";
import SearchFormButton from "./SearchFormButton";
import Loading from "../../Global/Loading";

function SearchForm({ criteria, setCriteria, executeScroll }) {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const onSubmitHandler = (e) => {
        e.preventDefault();
        search(dispatch, criteria);
    };
    useEffect(() => {
        getSearchData(dispatch);
    }, []); // eslint-disable-line
    useEffect(() => {
        search(dispatch, criteria);
        executeScroll();
    }, [criteria]); // eslint-disable-line

    return (
        
        <div className="col-sm-12 col-md-12 col-lg-4 order-2">
            <aside className="sidebar services-sidebar">
                <div className="sidebar-widget categories">
                    <div className="widget-content">
                        <h2 className="widget-title"> Search </h2>
                        <div className="booking">
                            <div className="contact-bg">
                                <form action="" onSubmit={onSubmitHandler} className="contact-form mt-30">
                                    {state.bookings.loading && <Loading />}
                                    
                                    <div className="row">
                                        <SearchFormMain
                                            criteria={criteria}
                                            setCriteria={setCriteria}
                                            cities={state.search && state.search.cities}
                                        />

                                        <SearchFormStars
                                            criteria={criteria}
                                            setCriteria={setCriteria}
                                        />

                                        <SearchFormPrice
                                            criteria={criteria}
                                            setCriteria={setCriteria}
                                        />

                                        <SearchFormFeatures
                                            criteria={criteria}
                                            setCriteria={setCriteria}
                                            features={state.search && state.search.features}
                                        />

                                        <SearchFormButton
                                            criteria={criteria}
                                            setCriteria={setCriteria}
                                        />
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>    
                </div>  
            </aside>
        </div>
    );
}

export default SearchForm;
