import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../../redux/actions/users";

function UserPersonalInfo({ user, id }) {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);

    const [userInfo, setUserInfo] = useState({
        id: "",
        first_name: "",
        last_name: "",
        phone: "",
        address: "",
        city: ""
    });
    const [image, setImage] = useState(null);

    useEffect(() => {
        setUserInfo({
            id,
            first_name: user && user.first_name ? user.first_name : "",
            last_name: user && user.last_name ? user.last_name : "",
            phone: user && user.phone ? user.phone : "",
            address: user && user.address ? user.address : "",
            city: user && user.city ? user.city : ""
        });
    }, [user]); // eslint-disable-line

    const onSubmitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("_method", "PUT");
        formData.append("id", id);
        formData.append("first_name", userInfo.first_name);
        formData.append("last_name", userInfo.last_name);
        formData.append("phone", userInfo.phone);
        formData.append("address", userInfo.address);
        formData.append("city", userInfo.city);

        image && formData.append("image", image);

        updateUserInfo(dispatch, state.auth.token, formData);
    };
    return (
        <div className="section-title center-align mb-40 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
            <h5>
                Change your personal info
            </h5>
            <form onSubmit={onSubmitHandler} method="post" className="contact-form mt-30">
                <div className="row">

                    <div className="flex items-center mt-3 mb-5">
                        <img
                            src={
                                user && user.image
                                    ? `${process.env.REACT_APP_BASE_URL}/img/users/${user.image}`
                                    : "http://placehold.it/300x300?text=avatar"
                            }
                            alt="avatar"
                            className="w-32 h-32 rounded-full object-cover"
                            style={{width: 150, borderRadius: '50%'}}
                        />

                        <label aria-label="upload image" className="uploaded-row cursor-pointer">
                            <input
                                type="file"
                                name=""
                                id=""
                                className="hidden"
                                onChange={(e) => {
                                    setImage(e.target.files[0]);
                                }}
                            />
                            <i className="fas fa-camera mr-2"></i>
                            {/* <span className="upload-conten">{image ? image.name : "Upload"}</span> */}
                        </label>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-name mb-20">                                    
                            <input 
                                type="text"
                                name="first_name"
                                id="first_name"
                                value={userInfo.first_name}
                                onChange={(e) =>
                                    setUserInfo({ ...userInfo, first_name: e.target.value })
                                }
                            />
                        </div>                               
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-name mb-20">                                    
                            <input 
                                type="text"
                                id="last_name"
                                name="last_name"
                                value={userInfo.last_name}
                                onChange={(e) =>
                                    setUserInfo({ ...userInfo, last_name: e.target.value })
                                }
                            />
                        </div>                               
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-name mb-20">                                    
                            <input 
                                type="tel"
                                id="phone"
                                name="phone"
                                value={userInfo.phone}
                                onChange={(e) =>
                                    setUserInfo({ ...userInfo, phone: e.target.value })
                                }
                            />
                        </div>                               
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-field p-relative c-name mb-20">                                    
                            <input 
                                type="text"
                                name="address"
                                id="address"
                                value={userInfo.address}
                                onChange={(e) =>
                                    setUserInfo({ ...userInfo, address: e.target.value })
                                }
                            />
                        </div>                               
                    </div>
                    
                    <div className="col-lg-12">
                        <div className="slider-btn">                                          
                            <button className="btn ss-btn" data-animation="fadeInRight" type="submit" data-delay=".8s"><span>Update</span></button>				
                        </div>                             
                    </div>
                </div>
            </form>
        </div> 
    );
}

export default UserPersonalInfo;
