import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function UserSiderBar() {
    const state = useSelector((state) => state);

    let { id } = useParams();
    let history = useHistory();

    useEffect(() => {
        // eslint-disable-next-line
        if (state.auth.user_id != id) {
            history.push({
                pathname: "/401",
                state: {
                    message:
                        "You are not authorized, you'll be redirected in a bit"
                }
            });
        }
    }, [state.auth.user_id]); // eslint-disable-line

    let location = useLocation();
    const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        switch (location.pathname) {
            case `/user-profile/${id}`:
                setCurrentPage(1);
                break;
            case `/user-bookings/${id}`:
                setCurrentPage(2);
                break;
            case `/user-reviews/${id}`:
                setCurrentPage(3);
                break;
            default:
                break;
        }
    }, []); // eslint-disable-line
    return (
        <div className="contact-info">
            <div className="single-cta pb-30 mb-30 wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                <div className="f-cta-icon">
                    <i className="far fa-user"></i>
                </div>
                <h5>Profile</h5>
                <p> 
                    <Link to={`/user-profile/${id}`}
                        className={`${
                            currentPage === 1 && "bg-gray-400"
                        }`}>
                        My Profile
                    </Link>
                </p>
            </div>
            <div className="single-cta pb-30 mb-30 wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                <div className="f-cta-icon">
                    <i className="far fa-ticket"></i>
                </div>
                <h5>Bookings</h5>
                <p> 
                    <Link to={`/user-bookings/${id}`}
                        className={`${
                            currentPage === 2 && "bg-gray-400"
                        }`}>
                        My Bookings
                    </Link>
                </p>
            </div>
            <div className="single-cta wow fadeInUp animated" data-animation="fadeInDown animated" data-delay=".2s">
                <div className="f-cta-icon">
                    <i className="far fa-user-md-chat"></i>
                </div>
                <h5>Reviews</h5>
                <p> 
                    <Link to={`/user-reviews/${id}`}
                        className={`${
                            currentPage === 3 && "bg-gray-400"
                        }`}>
                        My Reviews
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default UserSiderBar;
