import React from "react";
import { Link } from "react-router-dom";
import useSecureLs from "../Global/useSecureLs";

function GuestReview({
    review: { user_id, rating, content, first_name, last_name, image }
}) {
    const [_user_id] = useSecureLs("user_id");
    let _rating = "";
    switch (rating) {
        case 10:
            _rating = "10/10 Amazing";
            break;
        case 9:
            _rating = "9/10 Great";
            break;
        case 8:
            _rating = "8/10 Very Good";
            break;
        case 7:
            _rating = "7/10 Good";
            break;
        case 6:
            _rating = "6/10 Fine";
            break;
        case 5:
            _rating = "5/10 Average";
            break;
        case 4:
            _rating = "4/10 Bad";
            break;
        case 3:
            _rating = "3/10 Very Bad";
            break;
        case 2:
            _rating = "2/10 Horrible";
            break;
        case 1:
            _rating = "1/10 Appaling";
            break;

        default:
            break;
    }
    return (

        <div className="col-lg-6">
            <div className="testimonial-active">
                <div className="single-testimonial">
                    <div className="testi-author">
                        <img src={
                        image
                            ? `${process.env.REACT_APP_BASE_URL}/img/users/${image}`
                            : "http://placehold.it/80x80"
                    } alt="img" style={{borderRadius: '50%'}}/>
                        <div className="ta-info">
                            <h6>{first_name} {last_name}</h6>
                            <span>{_rating}</span>
                        </div>
                    </div>
                    <p>“{content}”.</p>
                    
                    <div className="qt-img">
                        {user_id === _user_id && (
                            <div className="mt-5 text-gray-200 absolute bottom-0 right-0 mb-2 mr-2">
                                <Link
                                    to={`/user-reviews/${user_id}`}
                                    className="text-xs font-semibold p-1 text-green-600 rounded-sm hover:text-green-900 cursor-pointer"
                                >
                                    <i className="fas fa-edit fa-lg"></i>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuestReview;
