import React from "react";

function Checkbox({ name, value, title, criteria, setCriteria }) {
    return (
        // <label className="wow">
        //     <input
        //         type="checkbox"
        //         value={value}
        //         onChange={(e) => {
        //             criteria[name].includes(value)
        //                 ? setCriteria({
        //                       ...criteria,
        //                       [name]: criteria[name].filter(
        //                           (val) => val !== e.target.value
        //                       )
        //                   })
        //                 : setCriteria({
        //                       ...criteria,
        //                       [name]: [...criteria[name], value]
        //                   });
        //         }}
        //     />
        //     <span className="checkmark">
        //         {value} {title}
        //     </span>
        // </label>

        <label className="checkbox d-flex align-content-center">
            <input 
                type="checkbox"
                value={value}
                onChange={(e) => {
                    criteria[name].includes(value)
                        ? setCriteria({
                              ...criteria,
                              [name]: criteria[name].filter(
                                  (val) => val !== e.target.value
                              )
                          })
                        : setCriteria({
                              ...criteria,
                              [name]: [...criteria[name], value]
                          });
                }}
            />
            <span style={{paddingLeft: 5}}>{value} {title}</span>
        </label>
    );
}

export default Checkbox;
