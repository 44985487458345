import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addReview } from "../../redux/actions/reviews";
import { setSuccess } from "../../redux/actions/global";
import ErrorMessage from "../Global/ErrorMessage";
import SuccessMessage from "../Global/SuccessMessage";
import useSecureLs from "../Global/useSecureLs";

function ReviewForm(props) {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [review, setReview] = useState({
        content: "",
        rating: "",
        user_id: "",
        hotel_id: ""
    });

    const onSubmitHandler = (e) => {
        e.preventDefault();
        addReview(dispatch, state.auth.token, review);
    };

    const [user_id] = useSecureLs("user_id");

    useEffect(() => {
        setReview({
            content: "",
            rating: 10,
            user_id,
            hotel_id: props.match.params.id
        });
    }, []); // eslint-disable-line

    useEffect(() => {
        const timer = setTimeout(() => {
            setSuccess(dispatch, null);
        }, 3000);
        return () => clearTimeout(timer);
    }, [state.reviews.success]); // eslint-disable-line

    return (
        <section className="booking pt-120 pb-120 p-relative fix">
            <div className="animations-01"><img src="smk/images/an-img-01.png" alt="an-img-01"/></div>
            <div className="container">
                <div className="row align-items-center">
                    {state.reviews.success === false && (
                        <ErrorMessage errors={state.reviews.errors} />
                    )}
                    {state.reviews.success && (
                        <SuccessMessage message="Your Review Has Been Posted" />
                    )}
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-bg02">
                            <div className="section-title center-align">
                                <h5>make appointment</h5>
                                <h2>Post A Review</h2>
                            </div>                                
                            <form onSubmit={onSubmitHandler} method="post" className="contact-form mt-30">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">                               
                                            <div className="contact-field p-relative c-subject mb-20">                                   
                                                <label><i className="fal fa-times-octagon"></i> Content</label>
                                                <textarea
                                                    rows="3"
                                                    value={review.content}
                                                    onChange={(e) =>
                                                        setReview({ ...review, content: e.target.value })
                                                    }
                                                ></textarea>
                                            </div>
                                    </div>		
                                    <div className="col-lg-6 col-md-6">                               
                                        <div className="contact-field p-relative c-subject mb-20">                                   
                                            <label><i className="fal fa-users"></i> Point</label>
                                            <select
                                                value={review.rating}
                                                onChange={(e) =>
                                                    setReview({ ...review, rating: e.target.value })
                                                }
                                            >
                                                <option value="10">10 Amazing</option>
                                                <option value="9">9 Great</option>
                                                <option value="8">8 Very Good</option>
                                                <option value="7">7 Good</option>
                                                <option value="6">6 Fine</option>
                                                <option value="5">5 Average</option>
                                                <option value="4">4 Bad</option>
                                                <option value="3">3 Very Bad</option>
                                                <option value="2">2 Horrible</option>
                                                <option value="1">1 Appaling</option>
                                            </select>
                                        </div>
                                    </div>	
                                    <div className="col-lg-12">
                                        <div className="slider-btn mt-15">                                          
                                            <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s" type="submit"><span>Submit</span></button>				
                                        </div>                             
                                    </div>
                                </div>
                            </form>                            
                        </div>         
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="booking-img">
                            <img src="smk/images/booking-img.png" alt="img"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ReviewForm;
