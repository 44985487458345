import React from "react";
import RoomCard from "../Global/RoomCard";

function HotelRooms({ hotel }) {
    return (
        <section id="services" className="services-area pt-120 pb-90">
            <div className="container">
                <div className="row">
                    {hotel &&
                        hotel.rooms.map((room) => {
                            return <RoomCard key={room.id} room={room} />;
                        })}
                </div>
            </div>
        </section>
    );
}

export default HotelRooms;
