import React from "react";

function SearchFormPrice({ criteria, setCriteria }) {
    return (
        <>
            <div className="col-lg-12">
                <div className="contact-field p-relative c-name mb-20">
                    <label htmlFor="min-price">Min: </label>
                    <input
                        id="min-price"
                        type="number"
                        value={criteria.min_price}
                        onChange={(e) => {
                            setCriteria({ ...criteria, min_price: e.target.value });
                        }}
                    />
                </div>
            </div>

            <div className="col-lg-12">
                <div className="contact-field p-relative c-name mb-20">
                    <label htmlFor="max-price">Max: </label>
                    <input
                        id="max-price"           
                        type="number"
                        value={criteria.max_price}
                        onChange={(e) => {
                            setCriteria({ ...criteria, max_price: e.target.value });
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default SearchFormPrice;
