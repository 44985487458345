import React, { useEffect } from "react";
import HotelCard from "./HotelCard";
// import TitleSection from "../Global/TitleSection";
import { getFeaturedHotels } from "../../redux/actions/hotels";
import { useSelector, useDispatch } from "react-redux";

function FeaturedHotels() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    useEffect(() => getFeaturedHotels(dispatch), []); // eslint-disable-line

    return (
            <section id="services" className="services-area pt-113 pb-150">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-xl-12">    
                          <div className="section-title center-align mb-50 text-center">
                              <h5>The pleasure of luxury</h5>
                              <h2>Selected Accommodations</h2>
                              <p>Preferred Partner and Preferred Partner Plus accommodation: popular, trusted, great reviews!</p>
                          </div>
                      </div>
                  </div>
                  <div className="row services-active">
                        {state && state.hotels.featuredHotels.map((hotel) => (
                                <HotelCard hotel={hotel} key={hotel.id} />
                        ))}
                  </div>
              </div>
            </section>
    );
}

export default FeaturedHotels;
