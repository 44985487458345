import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useSecureLs from "../Global/useSecureLs";

function Dashboard() {
    const state = useSelector((state) => state);
    const [id] = useSecureLs("user_id");
    let history = useHistory();
    useEffect(() => {
        if (!state.auth.is_admin) {
            history.push({
                pathname: "/401",
                state: {
                    message:
                        "You are not authorized, you'll be redirected in a bit"
                }
            });
        }
    }, [state.auth.is_admin]); // eslint-disable-line

    let location = useLocation();
    const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        switch (location.pathname) {
            case `/admin-profile/${id}`:
                setCurrentPage(1);
                break;
            case "/hotel-management":
                setCurrentPage(2);
                break;
            case "/room-management":
                setCurrentPage(3);
                break;
            case "/add-room":
                setCurrentPage(3);
                break;
            case "/booking-management":
                setCurrentPage(4);
                break;
            case "/review-management":
                setCurrentPage(5);
                break;
            default:
                break;
        }

        if (location.pathname.slice(0, 11) === "/edit-room/") {
            setCurrentPage(3);
        }
    }, []); // eslint-disable-line
    return (
        <div className="hidden md:block w-full md:w-4/12 xl:w-3/12 bg-gray-200 pt-5 lg:p-5 rounded-sm ">
            <h2>Oopss</h2>
        </div>
    );
}

export default Dashboard;
