import React from "react";
import Lightbox from "react-image-lightbox";

export default class MoreImage extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    };
    componentDidUpdate(prevProps) {
        if (this.props.images !== prevProps.images) {
            let _images =
                this.props.images !== null ? this.props.images.split(",") : [];
            this.setState({
                ...this.state,
                images: _images
            });
        }
    }

    render() {
        const { photoIndex, isOpen, images } = this.state;

        var settings = {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            arrows: false,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        };
        return (
            <>
                {images.length !== 0 && (
                    <div className="text-column col-xl-6 col-lg-12 col-md-12">
                        {isOpen && (
                            <Lightbox
                                mainSrc={`${process.env.REACT_APP_BASE_URL}/${images[photoIndex]}`}
                                nextSrc={
                                    images[(photoIndex + 1) % images.length]
                                }
                                prevSrc={
                                    images[
                                        (photoIndex + images.length - 1) %
                                            images.length
                                    ]
                                }
                                onCloseRequest={() =>
                                    this.setState({
                                        ...this.state,
                                        isOpen: false
                                    })
                                }
                                onMovePrevRequest={() =>
                                    this.setState({
                                        ...this.state,
                                        photoIndex:
                                            (photoIndex + images.length - 1) %
                                            images.length
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        ...this.state,
                                        photoIndex:
                                            (photoIndex + 1) % images.length
                                    })
                                }
                            />
                        )}
                        <figure className="image">
                            {images.map((image, i) => (
                                <img 
                                    src={
                                        images 
                                            ? `${process.env.REACT_APP_BASE_URL}/${image}` 
                                            : "http://placehold.it/406x239?text=image not available"}
                                    key={i}
                                    onClick={() =>
                                        this.setState({
                                            ...this.state,
                                            photoIndex: i,
                                            isOpen: true
                                        })
                                    }
                                />
                            ))}
                        </figure>
                    </div>
                )}
            </>
        );
    }
}
