import React from "react";
import Header from "../../Global/Header";
import Footer from "../../Global/Footer";
import UserSiderBar from "../UserSiderBar";
import UserDropDown from "../UserDropDown";
import UserBookingsList from "./UserBookingsList";

function UserBookings() {
    return (
        <div>
            <Header />
            {/* <main className="bg-gray-100 min-h-screen flex flex-col md:flex-row md:justify-center p-5 text-gray-800">
                <UserSiderBar />
                <UserDropDown />
                <UserBookingsList />
            </main> */}

                <main>
                    <section className="breadcrumb-area d-flex align-items-center" style={{backgroundImage: `url(https://images.unsplash.com/photo-1551882547-ff40c63fe5fa?auto=format&fit=crop&q=80&w=3870&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="breadcrumb-wrap text-center">
                                        <div className="breadcrumb-title">
                                            <h2>Bookings</h2>    
                                            <div className="breadcrumb-wrap">
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                                        <li className="breadcrumb-item active" aria-current="page">Bookings</li>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix">
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-4 order-1">
                                    <UserSiderBar /> 
                                </div>

                                <div className="col-lg-8 order-2">
                                    <UserBookingsList />
                                </div>
                            </div>
                        </div>
                    </section>

                </main>

            <Footer />
        </div>
    );
}

export default UserBookings;
